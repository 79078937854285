<template>
    <Dialog v-if="isOpen" v-model:visible="isOpen" :style="{ width: '750px' }" :modal="true">
        <template #header>
            <div>
                <h2>Parâmetros para geração do relatório de visita técnica</h2>
            </div>
        </template>

        <div class="field">
            <label for="cliente">Cliente</label>
            <DropdownCliente
                id="cliente"
                v-model="form.cliente"
                :showClear="true"
                :class="{ 'p-invalid': v$.form.cliente.$invalid && submitted }"
                class="w-full"
            />
            <small v-if="v$.form.cliente.$invalid && submitted" class="p-error">
                {{ v$.form.cliente.required.$message }}
            </small>
        </div>
        <div class="field">
            <label for="Unidade">Unidade</label>
            <InfinityDropdownUnidadesByCliente
                id="cliente"
                v-model="form.unidade"
                :disabled="enabledUnidade"
                class="w-full"
                :class="{ 'p-invalid': v$.form.unidade.$invalid && submitted }"
                :filtrosExtras="{ customerId: form?.cliente?.id || 0 }"
            />
            <small v-if="v$.form.unidade.$invalid && submitted" class="p-error">
                {{ v$.form.unidade.required.$message }}
            </small>
        </div>
        <div class="field-checkbox">
            <InputSwitch id="enviarPorEmail" v-model="form.enviarPorEmail" />
            <label for="enviarPorEmail">Enviar para o e-mail</label>
        </div>
        <div class="p-fluid">
            <Chips
                :class="{ 'p-invalid': shouldShowEmailError && submitted }"
                :disabled="!form.enviarPorEmail"
                v-model="form.emails"
                placeholder="Digite os e-mails"
                separator=";"
            />
            <small v-if="shouldShowEmailError && submitted" class="p-error">
                {{ emailErrorMessage }}
            </small>
        </div>
        <Message v-if="hasError" :severity="message.severity">{{ message.content }}</Message>
        <template #footer>
            <div class="flex flex-row justify-content-end">
                <div>
                    <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="close" />

                    <Button label="Executar" :loading="isLoading" @click="executar" />
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script>
import { getClientSesmt } from '../../../services/http-sesmt';
import DropdownCliente from '../../clientes/components/DropdownCliente.vue';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import axios from 'axios';
import { getTenant, getToken } from '../../../common/storage';
import InfinityDropdownUnidadesByCliente from '../../unidades/components/InfinityDropdownUnidadesByCliente.vue';

export default {
    emits: ['onSuccess'],
    setup: () => ({ v$: useVuelidate() }),
    components: {
        DropdownCliente,
        InfinityDropdownUnidadesByCliente
    },
    created() {
        this.$serviceRelatorio = getClientSesmt();
    },
    data() {
        return {
            form: {
                enviarPorEmail: false,
                emails: []
            },
            isOpen: false,
            enabledUnidade: true,
            submitted: false,
            isLoading: false,
            hasError: false,
            message: {}
        };
    },
    validations() {
        return {
            form: {
                cliente: { required: helpers.withMessage('Cliente deve ser informado', required) },
                unidade: { required: helpers.withMessage('Unidade deve ser informada', required) },
                emails: {
                    required: helpers.withMessage(
                        'Ao menos um e-mail deve ser informado',
                        requiredIf(() => this.form.enviarPorEmail)
                    ),
                    format: helpers.withMessage('Pelo menos um dos e-mails informados não é válido', (value) => {
                        if (!this.form.enviarPorEmail || !value || value.length === 0) {
                            return true;
                        }
                        return value.every((email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email));
                    })
                }
            }
        };
    },
    computed: {
        shouldShowEmailError() {
            return this.submitted && this.form.enviarPorEmail && (this.form.emails.length === 0 || this.v$.form.emails.format.$invalid);
        },
        emailErrorMessage() {
            if (this.form.emails.length === 0) {
                return this.v$.form.emails.required.$message;
            } else {
                return this.v$.form.emails.format.$message;
            }
        }
    },
    watch: {
        'form.cliente'(value) {
            if (value) {
                this.enabledUnidade = false;
                return;
            }
            this.limparFiltros();
            this.enabledUnidade = true;
        }
    },
    methods: {
        open() {
            this.isOpen = true;
        },
        close() {
            this.form.cliente = null;
            this.form.enviarPorEmail = false;
            this.form.emails = [];
            this.submitted = false;
            this.hasError = false;
            this.isLoading = false;
            this.isOpen = false;
        },
        limparFiltros() {
            this.form.unidade = null;
        },
        async executar() {
            this.hasError = false;
            this.submitted = true;
            this.isLoading = true;
            const result = await this.v$.form.$validate();

            if (!result) {
                this.isLoading = false;
                return;
            }

            const dto = {
                customer: {
                    id: this.form.cliente.id,
                    name: this.form.cliente.name
                },
                customerBranch: {
                    id: this.form.unidade.id,
                    name: this.form.unidade.name
                },
                enviarPorEmail: this.form.enviarPorEmail,
                emails: this.form.emails
            };
            if (!this.form.enviarPorEmail) {
                await this.gerarRelatorio(dto);
            } else {
                await this.enviarRelatorioPorEmail(dto);
            }
        },
        async gerarRelatorio(dto) {
            try {
                const response = await axios.post(process.env.VUE_APP_API_BASE_URL + `/inventario-risco/relatorios/gerar`, dto, {
                    method: 'POST',
                    responseType: 'blob',
                    headers: {
                        Authorization: `Bearer ${getToken()}`,
                        'x-api-key': getTenant()
                    }
                });

                const blob = new Blob([response.data], { type: 'application/pdf' });
                const blobURL = URL.createObjectURL(blob);

                window.open(blobURL, '_blank');

                this.close();
            } catch (error) {
                this.addMessageError(this.getMessageError(error));
            } finally {
                this.submitted = false;
                this.isLoading = false;
            }
        },
        async enviarRelatorioPorEmail(dto) {
            try {
                await this.$serviceRelatorio.post('inventario-risco/relatorios/enviar-por-email', dto);
                this.$emit('onSuccess');
                this.close();
            } catch (error) {
                this.addMessageError(this.getMessageError(error));
            } finally {
                this.submitted = false;
                this.isLoading = false;
            }
        },
        getMessageError(error) {
            const detailsMessages = error?.response?.data?.details?.response?.message;
            const messages = Array.isArray(detailsMessages) ? detailsMessages.join() : detailsMessages;
            const message = messages || error?.response?.data?.message || error.message;

            return message;
        },
        addMessageError(messageError) {
            this.hasError = true;
            this.message.severity = 'error';
            this.message.content = messageError ?? 'Ocorreu um problema';
        }
    }
};
</script>

<style>
</style>